@import url("https://fonts.googleapis.com/css?family=Poppins&display=swap");

body {
  font-family: "Poppins", sans-serif;
  background-color: #e7e9ed;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
